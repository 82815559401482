import React, { Component, useState, useRef, useEffect, Fragment } from 'react';
import Scrollspy from 'react-scrollspy';
import { Menu, X } from 'react-feather';
import Logo from '../../../images/logo/DEAL MAP-LOGO.png';
import { Link } from 'gatsby';
import { Container } from '../../global';
import {
  Nav,
  NavItem,
  Brand,
  StyledContainer,
  NavListWrapper,
  MobileMenu,
  Mobile,
  ActionsContainer,
  FeatureDropdownContainer,
} from './style';
import { FaAngleDown } from 'react-icons/fa';

const NAV_ITEMS = ['Features'];

const FeatureDropdown = () => {
  const wrapperRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  return (
    <div
      ref={wrapperRef}
      onMouseEnter={() => setIsVisible(true)}
      onMouseLeave={() => setIsVisible(false)}
    >
      <NavItem style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
        <Link to="/features">Features</Link>
        <FaAngleDown style={{ color: '#3a4661', marginLeft: '3px' }} />
      </NavItem>
      {isVisible && (
        <FeatureDropdownContainer>
          <div className="featureDropdown">
            <NavItem>
              <Link to="/features#deal-management">Deal Management</Link>
            </NavItem>
            <NavItem>
              <Link to="/features#accounting">Accounting</Link>
            </NavItem>
            <NavItem>
              <Link to="/features#integration">Integration</Link>
            </NavItem>
            <NavItem>
              <Link to="/features#security">Security</Link>
            </NavItem>
          </div>
        </FeatureDropdownContainer>
      )}
    </div>
  );
};

export default class Navigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileMenuOpen: false,
      hasScrolled: false,
      hostRoute: '',
      loginRoute: '/',
    };
  }

  getLoginRoute = (host) => {
    if (host.includes('dealmap')) {
      return 'https://app.dealmap.com';
    } else if (host.includes('dinghydeal')) {
      return 'https://app.dinghydeal.com';
    } else if (host.includes('thelonehipster')) {
      return 'https://app.thelonehipster.com';
    } else if (host.includes('splatmapper')) {
      return 'https://app.splatmapper.com';
    } else if (host.includes('localhost')) {
      return 'localhost:4000';
    } else {
      return '/';
    }
  };

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
    this.setState({ hostRoute: window.location.host });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.hostRoute !== prevState.hostRoute) {
      this.setState({ loginRoute: this.getLoginRoute(this.state.hostRoute) });
    }
  }
  handleScroll = (event) => {
    const scrollTop = window.pageYOffset;

    if (scrollTop > 32) {
      this.setState({ hasScrolled: true });
    } else {
      this.setState({ hasScrolled: false });
    }
  };

  toggleMobileMenu = () => {
    this.setState((prevState) => ({
      mobileMenuOpen: !prevState.mobileMenuOpen,
    }));
  };

  closeMobileMenu = () => {
    if (this.state.mobileMenuOpen) {
      this.setState({ mobileMenuOpen: false });
    }
  };

  //stripHash

  getNavList = ({ mobile = false }) => (
    <NavListWrapper mobile={mobile}>
      <Scrollspy
        items={NAV_ITEMS.map((item) => item.toLowerCase())}
        currentClassName="active"
        mobile={mobile}
        offset={-64}
      >
        {!mobile && <FeatureDropdown />}
        {mobile && (
          <React.Fragment>
            <NavItem onClick={this.closeMobileMenu}>
              <a href={`${this.state.loginRoute}/initial-signup`}>Sign Up</a>
            </NavItem>
            <NavItem onClick={this.closeMobileMenu}>
              <a href={`${this.state.loginRoute}/direct-login`}>Login</a>
            </NavItem>
            <NavItem
              style={{ display: 'flex', alignItems: 'center' }}
              onClick={this.closeMobileMenu}
            >
              <Link to="/features">Features</Link>
              <FaAngleDown style={{ color: '#3a4661', marginLeft: '3px' }} />
            </NavItem>

            <NavItem onClick={this.closeMobileMenu}>
              <Link to="/features#deal-management" style={{ marginLeft: '2rem' }}>
                Deal Management
              </Link>
            </NavItem>
            <NavItem onClick={this.closeMobileMenu}>
              <Link to="/features#accounting" style={{ marginLeft: '2rem' }}>
                Accounting
              </Link>
            </NavItem>
            <NavItem onClick={this.closeMobileMenu}>
              <Link to="/features#integration" style={{ marginLeft: '2rem' }}>
                Integration
              </Link>
            </NavItem>
            <NavItem onClick={this.closeMobileMenu}>
              <Link to="/features#security" style={{ marginLeft: '2rem' }}>
                Security
              </Link>
            </NavItem>
          </React.Fragment>
        )}
        <NavItem onClick={this.closeMobileMenu}>
          <Link to="/pricing">Pricing</Link>
        </NavItem>
        <NavItem onClick={this.closeMobileMenu}>
          <a href="https://support.dealmap.com" target="_blank" rel="noreferrer">
            Help
          </a>
        </NavItem>
      </Scrollspy>
    </NavListWrapper>
  );

  render() {
    const { mobileMenuOpen } = this.state;
    return (
      <Nav {...this.props} scrolled={this.state.hasScrolled} menuOpen={this.state.mobileMenuOpen}>
        <StyledContainer>
          <Link to="/">
            <Brand>
              <Scrollspy
                offset={-64}
                item={['top']}
                currentClassName="active"
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <img src={Logo} width="40px" style={{ marginRight: '10px' }} alt="logo" />
                <button onClick={this.closeMobileMenu}>Deal Map</button>
              </Scrollspy>
            </Brand>
          </Link>
          <Mobile>
            <button onClick={this.toggleMobileMenu} style={{ color: 'black', background: 'none' }}>
              {this.state.mobileMenuOpen ? (
                <X size={24} alt="close menu" />
              ) : (
                <Menu size={24} alt="open menu" />
              )}
            </button>
          </Mobile>

          <Mobile hide>{this.getNavList({})}</Mobile>
          <ActionsContainer>
            <a href={`${this.state.loginRoute}/initial-signup`} style={{ marginRight: '10px' }}>
              Sign up
            </a>
            <a href={`${this.state.loginRoute}/direct-login`}> Login </a>{' '}
          </ActionsContainer>
        </StyledContainer>
        <Mobile>
          {mobileMenuOpen && (
            <MobileMenu>
              <Container>{this.getNavList({ mobile: true })}</Container>
            </MobileMenu>
          )}
        </Mobile>
      </Nav>
    );
  }
}
