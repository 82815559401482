import styled from 'styled-components';

import { Container } from '../../global';

export const Nav = styled.nav`
  padding-top: 16px;
  padding-bottom: ${(props) => (props.menuOpen ? `0` : `16px`)};
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  background: white;
`;

export const StyledContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  a,
  a:hover,
  a:visited,
  a:active {
    text-decoration: none !important;
  }
`;

export const NavListWrapper = styled.div`
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;

    ${({ mobile }) =>
      mobile &&
      `
        flex-direction: column;
        margin-top: 1em;
        > ${NavItem} {
          padding: 0.75rem 16px;
          margin: 0;
        }
        > ${NavItem}:nth-child(odd) {
          background-color: #f7f7f7;
         
        }
      `};
  }
`;

export const NavItem = styled.li`
  margin: 0 0.75em;
  font-family: ${(props) => props.theme.font.medium};
  ${(props) => props.theme.font_size.xsmall};

  a {
    text-decoration: none;

    opacity: 0.9;
    color: ${(props) => props.theme.color.black.regular};
  }

  &.active {
    a {
      opacity: 1;
    }
  }
`;

export const MobileMenu = styled.div`
  width: 100%;
  background: white;
  z-index: 1000;
  > ${Container} {
    padding: 0;
    border-bottom: #d8d8d8 solid 1px;
  }
`;

export const Brand = styled.div`
  font-family: ${(props) => props.theme.font.extrabold};
  ${(props) => props.theme.font_size.regular};
  color: ${(props) => props.theme.color.black.regular};
  text-decoration: none;
  letter-spacing: 1px;
  margin: 0;
  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    a {
      color: ${(props) => props.theme.color.black.regular};
      text-decoration: none;
    }
  }
`;
export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: ${(props) => props.theme.screen.xs}) {
    display: none;
  }

  button,
  a {
    font-family: ${(props) => props.theme.font.normal};
    ${(props) => props.theme.font_size.xsmall};
    color: white;
    background: #01a1e8;
    border-radius: 4px;
    padding: 10px 16px;
    text-transform: uppercase;
    font-size: 12px;
  }
`;

export const Mobile = styled.div`
  display: none;

  @media (max-width: ${(props) => props.theme.screen.xs}) {
    display: block;
  }

  ${(props) =>
    props.hide &&
    `
    display: block;

    @media (max-width: ${props.theme.screen.xs}) {
      display: none;
    }
  `}
`;

export const FeatureDropdownContainer = styled.div`
  position: fixed;
  background: white;
  z-index: 1000;
  padding-top: 24px;

  .featureDropdown {
    width: 170px;
    padding: 10px 5px;
    border: #ededed 1px solid;
    border-top: none;
    border-radius: 0px 0px 5px 5px;

    li {
      padding: 8px 0px;
    }
  }
`;

// Background blur info
// background-color: ${props => props.scrolled && `rgba(245, 245, 250, .8`};
// box-shadow:  ${props =>
//   props.scrolled &&
//   `0 0 0 1px rgba(0,0,50,.02) inset, 0 1px 1px rgba(0,0,50,.05) inset, 0 2px 4px rgba(0,0,50,.04) inset`};
//   backdrop-filter: ${props => props.scrolled && `blur(15px)`};
